var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "shadow-none", attrs: { color: "second-card-bg" } },
    [
      _c(
        "CCardBody",
        { staticClass: "h-100 d-flex flex-column" },
        [
          _c("div", { staticClass: "table-wrap" }, [
            _c("table", { staticClass: "table mb-0" }, [
              _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.fields, function (field, i) {
                    return _c("th", { key: i, class: field._classes }, [
                      _vm._v(" " + _vm._s(field.label) + " "),
                    ])
                  }),
                  0
                ),
              ]),
              _c(
                "tbody",
                [
                  _vm._l(_vm.transactionsList, function (item, i) {
                    return [
                      _c("transaction-table-row", {
                        key: i,
                        attrs: { fields: _vm.fields, row: item },
                        on: { updateTable: _vm.getTransactions },
                      }),
                      item.related.length > 0
                        ? [
                            _c("Transition", { attrs: { name: "fade" } }, [
                              _c(
                                "tr",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.relatedOpen,
                                      expression: "item.relatedOpen",
                                    },
                                  ],
                                  key: `related-${i}`,
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "p-0",
                                      attrs: { colspan: _vm.fields.length },
                                    },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "nested-table table mb-0 border-bottom border-left border-right",
                                        },
                                        [
                                          _c("thead", [
                                            _c(
                                              "tr",
                                              _vm._l(
                                                _vm.fields,
                                                function (field, i) {
                                                  return _c(
                                                    "th",
                                                    {
                                                      key: i,
                                                      class: field._classes,
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(field.label) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                          _c(
                                            "tbody",
                                            [
                                              _vm._l(
                                                item.related,
                                                function (related, j) {
                                                  return [
                                                    _c(
                                                      "transaction-table-row",
                                                      {
                                                        key: j,
                                                        attrs: {
                                                          fields: _vm.fields,
                                                          row: related,
                                                        },
                                                        on: {
                                                          updateTable:
                                                            _vm.getTransactions,
                                                        },
                                                      }
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
          _c("CPagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.totalPages > 1,
                expression: "totalPages > 1",
              },
            ],
            staticClass: "mt-2 pagination-custom",
            class: { _disabled: _vm.loadingTable },
            attrs: { activePage: _vm.page, pages: _vm.totalPages },
            on: {
              "update:activePage": function ($event) {
                _vm.page = $event
              },
              "update:active-page": function ($event) {
                _vm.page = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }