<template>
  <div class="transactions d-flex flex-column">
    <div class="mb-3 d-flex align-items-center">
      <h1 class="mb-0 title">История транзакций</h1>

      <v-select-input
        v-if="$store.getters['user/getPermissions']('view_payments_all')"
        v-model="user"
        class="mb-0 flex-grow-1 v-select-input"
        :options="userList"
        :get-option-label="option => option.email"
        :placeholder="$t('lk.payments.searchPlaceholder')"
        @search="onSearch"
      >
        <template #prepend>
          <CButton class="shadow-none" color="primary">
            <CIcon name="cil-search"/> {{$t('lk.payments.search')}}
          </CButton>
        </template>
        <template #no-options>
          {{$t('general.emptySearch')}}
        </template>
      </v-select-input>
    </div>

    <div class="flex-grow-1 overflow-hidden">
      <transactions-table
        ref="table"
        :user="user"
        class="h-100 overflow-hidden"
      />
    </div>
  </div>
</template>

<script>
import VSelectInput from "@/components/VSelectInput";
import debounce from "lodash/debounce";
import axios from "axios";
import TransactionsTable from "@/components/lk/transactions/transactions-table";

export default {
  name: "Transactions",
  components: {
    TransactionsTable,
    VSelectInput
  },
  data() {
    return {
      user: null,
      userList: [],
      loadingRequest: false
    }
  },
  methods: {
    onSearch(search, loading) {
      if(search.length && search.length >= 3) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      axios.get('api/v1/users/autocomplete', {params: {term: search}})
        .then(res => {
          vm.userList = res.data.data
        })
        .finally(() => {
          loading(false);
        })
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
.transactions {
  @include media-breakpoint-down(lg) {
    @media (min-height: 550px) {
      height: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    @media (min-height: 700px) {
      height: 100%;
    }
  }
}
.title {
  @include media-breakpoint-down(xs) {
    font-size: 2rem;
    margin-right: 1.5rem;
    width: min-content;
  }
  @include media-breakpoint-up(sm) {
    margin-right: 3rem;
  }
}

/deep/ .v-select-input {
  @include media-breakpoint-down(xs) {
    .input-group-prepend {
      display: none;
    }
  }
  @include media-breakpoint-up(sm) {
    padding-right: 1.5rem;
  }

  .input-group-text {
    padding: 0;
    border: none;
  }
}
</style>
