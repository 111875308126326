import {get, has} from 'lodash'
import User from "@/assets/js/User.class";
import TransactionMeta from "@/assets/js/TransactionMeta.class";
import moment from "moment";
import store from "@/store";

const STATUS_NEW = 'new'
const STATUS_PENDING = 'pending'
const STATUS_CANCELED = 'canceled'
const STATUS_SUCCEEDED = 'succeeded'

const STATUSES = {
  [STATUS_NEW]: {
    'label': 'Новый',
    '_classes': 'text-primary',
  },
  [STATUS_PENDING]: {
    'label': 'В ожидании',
    '_classes': 'text-warning',
  },
  [STATUS_CANCELED]: {
    'label': 'Отменен',
    '_classes': 'text-danger',
  },
  [STATUS_SUCCEEDED]: {
    'label': 'Завершен',
    '_classes': 'text-success',
  },
}

/** Тип операции по переводу пользователем через систему мерчант (модель Payment) */
const TYPE_BONUS_INCOME = 'bonus_income';
/** Тип списания пользователем на оплату тарифа */
const TYPE_BONUS_OUTCOME = 'bonus_outcome';
const TYPE_BONUS_OUTCOME_STANDARD = 'bonus_outcome_standard';
/** Тип пополнения счета бонусами */
const TYPE_REFERRAL_INCOME = 'referral_income';
/** Тип списания/обналичивания бонусов */
const TYPE_REFERRAL_OUTCOME = 'referral_outcome';

const TYPES = {
  [TYPE_BONUS_INCOME]: {
    label: 'Приход',
    '_classes': 'text-success',
  },
  [TYPE_BONUS_OUTCOME]: {
    label: 'Расход',
    '_classes': 'text-danger',
  },
  [TYPE_BONUS_OUTCOME_STANDARD]: {
    label: 'Расход',
    '_classes': 'text-danger',
  },
  [TYPE_REFERRAL_INCOME]: {
    label: 'Приход на реферальный счет',
    '_classes': 'text-success',
  },
  [TYPE_REFERRAL_OUTCOME]: {
    label: 'Снятие с реферального счета',
    '_classes': 'text-danger',
  },
}

export default class Transaction {
  constructor(init = {}) {
    moment.locale(store.state.settings.global.locale)
    this.id = get(init, 'id', 0)
    this.cost = get(init, 'cost', 0)
    this.comment = get(init, 'comment', '')
    this.createdAt = get(init, 'created_at')
    this.createdAtFormatted = this.createdAt ? moment(this.createdAt).format('L') : '-'
    this.deletedAt = get(init, 'deleted_at')
    this.updatedAt = get(init, 'updated_at')
    this.updatedAtFormatted = this.updatedAt ? moment(this.updatedAt).format('L') : '-'
    this.statusInit = get(init, 'status')
    this.typeInit = get(init, 'type')
    this.user =
      has(init, 'user_email') && has(init, 'user_id') ?
        new User({
          email: init.user_email,
          id: init.user_id
        }) : {}
    this.related =
      has(init, 'related') && init.related.length > 0 ?
        init.related.map(item => new Transaction(item)) : []

    this.meta = has(init, 'meta') ? new TransactionMeta(init.meta) : []

    this.relatedOpen = false
    this.editStatus = false
    this.loadingRequest = false
  }

  get userEmail() {
    return this.user.email
  }

  get status() {
    return STATUSES[this.statusInit]
  }

  get type() {
    return TYPES[this.typeInit]
  }

  get statusList() {
    return Object.keys(STATUSES).map(key => {
      return {
        label: STATUSES[key].label,
        value: key
      }
    })
  }
}
