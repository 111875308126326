<template>
  <tr
    :class="{'_clickable': row.related.length > 0}"
    @click="row.relatedOpen = !row.relatedOpen"
  >
    <td
      v-for="(field, i) in fields"
      :key="'field-'+i"
      :class="get(row, `${field.key}._classes`)"
    >
      <template v-if="field.key === 'status'">
        <span class="text-nowrap d-flex flex-nowrap align-items-center status-field">
          <CSelect
            :disabled="!row.editStatus"
            :value.sync="row.statusInit"
            :options="row.statusList"
            class="mb-0 flex-0"
          />
          <template v-if="row.editStatus">
            <CLoadingButtonCustom
              color="primary"
              size="sm"
              :loading="row.loadingRequest"
            >
              <CIcon name="cil-save" @click.native.stop="saveEdit(row)"/>
            </CLoadingButtonCustom>
            <CButton
              v-if="!row.loadingRequest"
              color="warning"
              size="sm"
            >
              <CIcon name="cil-x" @click.native.stop="disableEdit(row)"/>
            </CButton>
          </template>
          <CButton
            v-else
            color="primary"
            size="sm"
          >
            <CIcon name="cil-pencil" @click.native.stop="enableEdit(row)"/>
          </CButton>
        </span>
      </template>
      <template v-else-if="field.key === 'type'">
        {{ get(row, field.key).label }}
      </template>
      <template v-else>
        {{ has(row, field.key) && !!get(row, field.key) ? get(row, field.key) : '-' }}
      </template>
    </td>
    <CIcon v-if="row.related.length > 0" :class="{'_rotated': row.relatedOpen, 'arrow': true}" name="arrow-strong"/>
  </tr>
</template>

<script>
import {get, has} from 'lodash'
import axios from "axios";

export default {
  name: "TableRow",
  props: ['fields', 'row'],
  data() {
    return {
      get,
      has,
    }
  },
  methods: {
    enableEdit(row) {
      row.editStatus = true
    },
    disableEdit(row) {
      row.editStatus = false
    },
    saveEdit(row) {
      row.loadingRequest = true
      axios.put('/api/v2/transactions', {
        'status': row.statusInit,
        'id': row.id
      })
      .then(({data}) => {
        if (data?.status) {
          this.$toastr.success('Статус изменен');
          this.$emit('updateTable')
          row.editStatus = false
        }
      })
      .catch((er) => {
        console.error(er)
      })
      .finally(() => {
        row.loadingRequest = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-icon {
  color: var(--analytic-item-count);
  cursor: pointer;
}

.status-field {
  width: 200px;
  gap: 6px;
}
</style>
