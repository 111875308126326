import {get, has} from 'lodash'
import moment from "moment";
import store from '@/store'

export default class TransactionMeta {
  constructor(init = {}) {
    moment.locale(store.state.settings.global.locale)
    this.tariffId = get(init, 'tariff_id')
    this.tariffDays = get(init, 'tariff_days')
    if (this.tariffDays) {
      this.tariffDays = Math.floor(this.tariffDays) + 1
    }
    this.tariffStart = has(init, 'tariff_date_start') && init.tariff_date_start ? moment(init.tariff_date_start).format('L') : '-'
    this.tariffEnd = has(init, 'tariff_date_end') && init.tariff_date_end ? moment(init.tariff_date_end).format('L') : '-'
  }
}
