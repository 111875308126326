<template>
  <CCard
    color="second-card-bg"
    class="shadow-none"
  >
    <CCardBody class="h-100 d-flex flex-column">
      <div class="table-wrap">
        <table class="table mb-0">
          <thead>
          <tr>
            <th v-for="(field, i) in fields" :key="i" :class="field._classes">
              {{ field.label }}
            </th>
          </tr>
          </thead>

          <tbody>
          <template v-for="(item, i) in transactionsList">
            <transaction-table-row
              :key="i"
              :fields="fields"
              :row="item"
              @updateTable="getTransactions"
            />
            <template v-if="item.related.length > 0">
              <Transition name="fade">
                <tr :key="`related-${i}`" v-show="item.relatedOpen">
                  <td :colspan="fields.length" class="p-0">
                    <table class="nested-table table mb-0 border-bottom border-left border-right">
                      <thead>
                      <tr>
                        <th v-for="(field, i) in fields" :key="i" :class="field._classes">
                          {{ field.label }}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(related, j) in item.related">
                        <transaction-table-row
                          :fields="fields"
                          :row="related"
                          :key="j"
                          @updateTable="getTransactions"
                        />
                      </template>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </Transition>
            </template>
          </template>
          </tbody>
        </table>
      </div>
      <CPagination
        class="mt-2 pagination-custom"
        :class="{_disabled: loadingTable}"
        v-show="totalPages > 1"
        :activePage.sync="page"
        :pages="totalPages"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import Transaction from "@/assets/js/Transaction.class";
import {get, has} from "lodash";
import TransactionTableRow from "@/components/transactions/TransactionTableRow";

export default {
  name: "transactions-table",
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  components: {
    TransactionTableRow,
  },
  data() {
    return {
      loadingTable: false,
      transactionsList: [],
      page: 1,
      totalPages: null,
      get,
      has,
      fields: [
        {key: 'id', _classes: 'align-middle', label: 'ID'},
        {key: 'cost', _classes: 'align-middle', label: 'Сумма'},
        {key: 'status', _classes: 'align-middle', label: 'Статус'},
        {key: 'type', _classes: 'align-middle', label: 'Тип операции'},
        {key: 'user.email', _classes: 'align-middle', label: this.$t(('lk.payments.email'))},
        {key: 'comment', _classes: 'align-middle', label: 'Комментарий'},
        {key: 'meta.tariffId', _classes: 'align-middle', label: 'Тариф'},
        {key: 'meta.tariffDays', _classes: 'align-middle', label: 'Дни'},
        {key: 'meta.tariffStart', _classes: 'align-middle', label: 'Начало'},
        {key: 'meta.tariffEnd', _classes: 'align-middle', label: 'Конец'},
        {key: 'createdAtFormatted', _classes: 'align-middle', label: 'Создана'},
        {key: 'updatedAtFormatted', _classes: 'align-middle', label: 'Обновлена'},
      ]
    }
  },
  created() {
    this.getTransactions()
  },
  watch: {
    user: {
      deep: true,
      handler: function () {
        this.getTransactions()
      }
    },
    page(val, oldVal) {
      if (val && val !== oldVal) {
        this.getTransactions()
      }
    }
  },
  computed: {
    isNotProduction() {
      return process.env.NODE_ENV !== 'production' || !!process.env.VUE_APP_DEV
    }
  },
  methods: {
    getTransactions() {
      const params = {
        page: this.page
      }

      if (this?.user?.id) params.user_id = this.user.id

      this.loadingTable = true
      axios.get('api/v2/transactions', {params})
        .then(({data, status}) => {
          if (status < 300) {
            this.transactionsList = data.data.map(item => new Transaction(item))
            this.page = data.meta.current_page
            this.totalPages = data.meta.last_page
          }
        })
        .finally(() => {
          this.loadingTable = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-wrap {
  overflow: auto;
  width: 100%;
  flex-grow: 1;
}

.table {
  position: relative;

  thead tr th {
    position: sticky;
    top: -1px;
    z-index: 1;
    background: var(--v-select-bg);
  }

  tr._clickable {
    cursor: pointer;
    background: var(--v-select-bg);
    position: relative;

    &:hover {
      background: var(--dark);
    }

    & /deep/ .c-icon.arrow {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      transition: all 0.5s ease;

      &._rotated {
        transform: translateY(-50%) rotate(0);
      }
    }
  }
}

.nested-table {
  background: var(--v-select-bg);
  thead tr th {
    background: var(--v-select-bg);
  }
  tr {
    background: var(--dark);
  }
}

.pagination-custom {
  /deep/ .pagination {
    margin-bottom: 0;
  }

  /deep/ .page-item.active .page-link {
    background-color: var(--primary);
  }

  &._disabled {
    /deep/ .page-item {
      position: relative;
      opacity: 0.5;

      &:after {
        content: "";
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        z-index: 999;
        cursor: not-allowed;
      }
    }
  }
}
</style>

