var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: { _clickable: _vm.row.related.length > 0 },
      on: {
        click: function ($event) {
          _vm.row.relatedOpen = !_vm.row.relatedOpen
        },
      },
    },
    [
      _vm._l(_vm.fields, function (field, i) {
        return _c(
          "td",
          {
            key: "field-" + i,
            class: _vm.get(_vm.row, `${field.key}._classes`),
          },
          [
            field.key === "status"
              ? [
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-nowrap d-flex flex-nowrap align-items-center status-field",
                    },
                    [
                      _c("CSelect", {
                        staticClass: "mb-0 flex-0",
                        attrs: {
                          disabled: !_vm.row.editStatus,
                          value: _vm.row.statusInit,
                          options: _vm.row.statusList,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.row, "statusInit", $event)
                          },
                        },
                      }),
                      _vm.row.editStatus
                        ? [
                            _c(
                              "CLoadingButtonCustom",
                              {
                                attrs: {
                                  color: "primary",
                                  size: "sm",
                                  loading: _vm.row.loadingRequest,
                                },
                              },
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-save" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.saveEdit(_vm.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            !_vm.row.loadingRequest
                              ? _c(
                                  "CButton",
                                  { attrs: { color: "warning", size: "sm" } },
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-x" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.disableEdit(_vm.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _c(
                            "CButton",
                            { attrs: { color: "primary", size: "sm" } },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-pencil" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.enableEdit(_vm.row)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    2
                  ),
                ]
              : field.key === "type"
              ? [_vm._v(" " + _vm._s(_vm.get(_vm.row, field.key).label) + " ")]
              : [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.has(_vm.row, field.key) &&
                          !!_vm.get(_vm.row, field.key)
                          ? _vm.get(_vm.row, field.key)
                          : "-"
                      ) +
                      " "
                  ),
                ],
          ],
          2
        )
      }),
      _vm.row.related.length > 0
        ? _c("CIcon", {
            class: { _rotated: _vm.row.relatedOpen, arrow: true },
            attrs: { name: "arrow-strong" },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }